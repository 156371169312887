<template>
  <div class="step-two row g-3">
    <div class="col-md-12">
      <label class="form-label" for="salary">What is your expected salary ?*</label>
      <div class="input-group">
        <div :class="{'is-invalid' : errors.first('salary')}" class="input-group-text">BDT</div>
        <input id="salary" v-model="expected_salary" v-validate="'required'" class="form-control" name="salary"
               placeholder="25000" type="number" max="1000000">
      </div>
      <div class="d-flex">
        <span v-if="errors.first('salary')" class="error-msg me-auto">{{ errors.first('salary') }}</span>
        <span class="salary-text">Salary Range : {{ $parent.$data.job.job_summery.salary_range }}</span>
      </div>
    </div>
    <div v-for="(additional_field, index) in $parent.$data.job.additional_fields" class="col-md-12">
      <label class="form-label">{{ additional_field.title }}
        {{ additional_field.required ? '*' : '' }}</label>
      <!--             v-validate="additional_field.required ? 'required' : ''"-->
      <input v-model.trim="additional_fields[index]"
             :name="additional_field.title"
             class="form-control" type="text">
      <span class="error-msg">{{ errors.first(`${additional_field.title}`) }}</span>
      <span class="error-msg">{{ showError(`additional_fields.${index}`) }}</span>
    </div>
    <div class="col-md-12">
      <label class="form-label" for="say_to_us">Do you have anything to say to us?</label>
      <textarea id="say_to_us" v-model="additional_message" class="form-control"
                cols="30" name="additional message" rows="7"></textarea>
      <span class="error-msg">{{ errors.first('additional message') }}</span>
      <span class="error-msg message-error">{{ showError('message') }}</span>
    </div>
    <!--    <div class="col-12">-->
    <!--      <vue-recaptcha :sitekey="sitekey" :loadRecaptchaScript="true"></vue-recaptcha>-->
    <!--    </div>-->
    <div class="row">

      <div class="col-12">
        <div class="d-flex justify-content-between">
          <button v-if="!$store.state.activeUser" class="btn float-end btn-animate prev-page"
                  @click.prevent="$parent.activeStep=0">
            <img alt="" src="/assets/arrow-right2.svg">Previous
          </button>
          <button class="btn-animate btn float-end" type="submit" @click.prevent="applyJob()">
            Submit <img alt="" src="/assets/arrow-right2.svg">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name      : "JobApplyExtraInfo",
  components: {VueRecaptcha},
  data      : () => ({
    sitekey           : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    expected_salary   : '',
    additional_message: '',
    additional_fields : []
  }),
  methods   : {
    onSubmit : function () {
      this.$refs.invisibleRecaptcha.execute()
    },
    onVerify : function (response) {
      console.log('Verify: ' + response)
    },
    onExpired: function () {
      console.log('Expired')
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset() // Direct call reset method
    },

    applyJob() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$parent.submit()
        }
      })
    },
    showError(errorType) {
      let errors = null;
      for (let error in this.$parent.from_errors[0]) {
        if (error == errorType) {
          errors = 'message' == error ? this.$parent.from_errors[0][error] : this.$parent.from_errors[0][error][0];
          break;
        }
      }
      return errors;
    }
  },
  mounted() {
    // console.log('job extra', this.$parent.$data.job.additional_fields)
    setTimeout(() => {
      let len = this.$parent.$data.job.additional_fields.length
      console.log(len)
      this.additional_fields = new Array(len)
    }, 1000)

  }
}
</script>

<style scoped>

</style>