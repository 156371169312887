<template>
  <div class="step-one row g-3">
    <div class="col-md-12">
      <label class="form-label" for="full_name">Full Name*</label>
      <input id="full_name" v-model.trim="candidate_info.full_name" v-validate="'required'" class="form-control"
             name="full name"
             placeholder="Enter Your Name" type="text">
      <span class="error-msg">{{ errors.first('full name') }}</span>
    </div>
    <div class="col-lg-6 col-md-12">
      <label class="form-label" for="email">Email address*</label>
      <input id="email" v-model="candidate_info.email" v-validate="'required|email'" class="form-control" name="email"
             placeholder="Enter Your Email" type="text">
      <span class="error-msg">{{ errors.first('email') }}</span>
      <span v-if="duplicate_candidate_info.email === candidate_info.email" class="error-msg">{{
          showError('email')
        }}</span>
    </div>
    <div class="col-lg-6 col-md-12">
      <label class="form-label" for="phone">Phone Number*</label>
      <div class="input-group">
        <div class="input-group-text">+880</div>
        <input id="phone" v-model="candidate_info.phone" v-validate="'required|min:10|max:10'" class="form-control"
               name="phone"
               :class="candidate_info.phone ? 'in-valid' : ''"
               placeholder="xxxxxxxxxx" type="number">
      </div>
      <span class="error-msg">{{ errors.first('phone') }}</span>
      <span v-if="duplicate_candidate_info.phone === candidate_info.phone" class="error-msg">{{
          showError('phone')
        }}</span>
    </div>
    <div class="col-lg-6 col-md-12">
      <label class="form-label" for="password">Password*</label>
      <PasswordField  v-model="candidate_info.password"  placeholder="Enter Your Password"/>
<!--      <input id="password" ref="password" v-model="candidate_info.password" v-validate="'required|min:6|max:40'"
             class="form-control"
             name="password"
             placeholder="Enter Your Password" type="password">-->
      <span class="error-msg">{{ errors.first('password') }}</span>
    </div>
    <div class="col-lg-6 col-md-12">
      <label class="form-label" for="re-password">Re-Type Password*</label>
      <PasswordField  v-model="candidate_info.re_type_password" placeholder="Enter Your Re-Type Password" />
<!--      <input id="re-password" v-model="candidate_info.re_type_password" v-validate="'required|confirmed:password'"
             class="form-control"
             name="re password"
             placeholder="Enter Your Re-Type Password" type="password">-->
      <span class="error-msg">{{ errors.first('re password') }}</span>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="d-flex">
        <div class="align-items-center d-flex form-check form-check-inline gap-2">
          <input class="form-check-input" type="radio" name="gender" id="male" value="male" v-model="candidate_info.gender" v-validate="'required'" style="height: 18px; width: 18px">
          <label class="form-check-label" for="male">Male</label>
        </div>
        <div class="align-items-center d-flex form-check form-check-inline gap-2">
          <input class="form-check-input" type="radio" name="gender" id="female" value="female" v-model="candidate_info.gender" v-validate="'required'" style="height: 18px; width: 18px">
          <label class="form-check-label" for="female">Female</label>
        </div>
      </div>

      <span class="error-msg">{{ errors.first('gender') }}</span>
    </div>
    <div class="col-md-12">
      <label class="form-label" for="resume">CV/Resume*</label>
      <div class="dropzone text-center" @click="[hideText=0]">
        <div v-if="!candidate_info.cv" class="text">
          <img alt="" src="/assets/file.svg">
          <h5>Drag and drop or <span>Browse</span></h5>
          <p>
            Please upload your cv or resume only in word document or pdf file with your photography in it.
          </p>
        </div>
        <input id="resume" ref="resume" v-validate="'required|ext:docx,pdf,doc'" accept="application/pdf"
               class="form-control"
               name="cv" type="file">
        <div v-if="candidate_info.cv" class="show-file">
          <img :src="`/assets/${file_image}`" alt="your cv"/>
          <p>{{ candidate_info.cv.name }}</p>
        </div>
      </div>
      <span class="error-msg">{{ errors.first('cv') }}</span>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <button class="btn-animate btn float-end" @click.prevent="goNext">
          Continue <img alt="" src="/assets/arrow-right2.svg">
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import PasswordField from "@/components/PasswordField.vue";

export default {
  name: "JobApplyRegister",
  components: {PasswordField},
  data: () => ({
    file_error: false,
    file_image: '',
    candidate_info: {
      full_name: '',
      email: '',
      password: '',
      re_type_password: '',
      phone: '',
      cv: '',
      gender:''
    },
    duplicate_candidate_info: {},
  }),
  methods: {
    goNext() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$parent.activeStep = 1;
          this.duplicate_candidate_info = {
            email: this.candidate_info.email,
            phone: this.candidate_info.phone,
          }
        }
      })
    },
    showError(errorType) {
      let errors = null;
      for (let error in this.$parent.from_errors[0]) {
        if (error == errorType) {
          errors = this.$parent.from_errors[0][error][0];
          break;
        }
      }
      return errors;
    }
  },
  mounted() {
    this.$refs.resume.onchange = evt => {
      let accept_files = ['docx', 'pdf']
      let fileName = document.querySelector('#resume').value;
      let extension = fileName.split('.').pop();
      if (accept_files.includes(extension)) {
        this.file_image = `${extension}.png`
        this.candidate_info.cv = this.$refs.resume.files[0]
      } else {
        this.file_image = ''
        this.candidate_info.cv = ''
      }
    }
  }
}
</script>

<style scoped>

</style>