<template>
  <div class="container">
    <div class="step-final row g-3 py-5">
      <div class="col-md-12 text-center">
        <div class="img">
          <img src="/assets/success.svg" alt="">
        </div>
        <h3>Successful</h3>
        <p>
          Your job application for <b>{{ job.title }}</b> at
          Mediusware has successfully completed.
          <br>After initial screening process we will get back to you.
        </p>
        <div class="d-flex justify-content-center">
          <router-link to="/user" class="btn-outline">Your Profile</router-link>
          <router-link to="/" class="btn">Back to Job Page</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "JobApplySuccess",
  data() {
    return {
      job: {
        job_summery: {}
      }
    }
  },
  mounted() {
    axios.get(`/job/${this.$route.params.slug}`).then(res => {
      this.job = res.data
    })
  }
}
</script>

<style scoped>

</style>