<template>
  <div class="job-apply">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <div class="job-apply-section shadow">
            <div v-if="showTitle==0" class="show-title">
              <div class="logo text-center">
                <img src="assets/logo.svg" alt="">
              </div>
              <div class="border-bottom title">
                <div class="job-title">Job application for “{{ job.title ? job.title : "" }}”</div>
                <div class="already-login">
                  <p v-if="!$store.state.activeUser">If you already have Mediusware job account then please
                    <router-link :to="{path : '/login', query : { nextUrl : `${job.slug}/apply`}}">login
                    </router-link>
                  </p>
                </div>
              </div>


            </div>
            <form method="post" action="" enctype="multipart/form-data">
              <div v-show="activeStep===0">
                <JobApplyRegister ref="candidate"/>
              </div>
              <div v-show="activeStep===1">
                <job-apply-extra-info ref="job_extra"/>
              </div>
            </form>
            <div v-if="activeStep===2">
              <job-apply-success/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobApplyRegister from "./JobApplyRegister";
import JobApplyExtraInfo from "./JobApplyExtraInfo";
import JobApplySuccess from "./JobApplySuccess";
import axios from "@/axios";

export default {
  name: "JobDetails",
  components: {JobApplySuccess, JobApplyExtraInfo, JobApplyRegister},
  data: () => ({
    activeStep: 0,
    showTitle: 0,
    from_errors: [],
    job: {
      job_summery: {},
      additional_fields: []
    },
    candidate_information: {
      full_name: '',
      email: '',
      password: '',
      re_type_password: '',
      phone: '',
      cv: ''
    },
    extra_information: {
      job_slug: '',
      expected_salary: '',
      additional_message: ''
    }
  }),
  mounted() {
    console.log(this.$store.state)
    if (this.$store.state.activeUser) {
      this.activeStep = 1
    }
    axios.get(`/job/${this.$route.params.slug}`).then(res => {
      this.job = res.data
      this.$nextTick(() => {
        document.title = this.job.title + "- Mediusware Ltd"
      })
      console.log(res.data)
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    register() {
      let formData = new FormData()
      for (let key in this.$refs.candidate.$data.candidate_info) {
        formData.append(key, this.$refs.candidate.$data.candidate_info[key])
      }
      return axios.post('/register-candidate/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
    },

    login() {
      return axios.post('/login/', {
        'email': this.$refs.candidate.$data.candidate_info.email,
        'password': this.$refs.candidate.$data.candidate_info.password
      })
    },

    applyForJob() {
      return axios.post('/apply/', {
        'job_slug': this.$route.params.slug,
        'expected_salary': this.$refs.job_extra.$data.expected_salary,
        'additional_message': this.$refs.job_extra.$data.additional_message,
        'additional_fields': this.$refs.job_extra.$data.additional_fields
      }, {
        headers: {'Authorization': `Bearer ${this.$store.state.token}`}
      }).then(data => {
        this.from_errors = []
        this.$router.push({name: 'jobApplySuccess', params: {slug: this.$route.params.slug}})
      }).catch(error => {
        this.from_errors = [];
        this.from_errors.push(error.response.data);
      })
    },

    async submit() {
      if (this.$store.state.activeUser) {
        this.applyForJob().then(data => {
          console.log(data)
        })
      } else {
        let registered = false
        await this.register().then(() => {
          registered = true
        }).catch(error => {
          this.from_errors = [];
          this.from_errors.push(error.response.data);
          for (let error in this.from_errors[0]) {
            if (error == "phone" || error == "email") {
              this.activeStep = 0;
            }
          }
        })

        if (registered) {
          await this.login().then(res => {
            this.$store.commit('UPDATE_USER', res.data)
            this.$store.commit('SET_TOKEN', res.data._token)
          })

          await this.applyForJob()
        }


      }
    }
  }

}
</script>

